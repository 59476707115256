<template>
  <el-card class="box-card">
    <h3>活动信息</h3>
    <div class="show_list">
      <div class="show_item">
        活动名称：<span>{{ activeInfo.name }}</span>
      </div>
      <div class="show_item">
        活动ID：<span>{{ activeInfo.id }}</span>
      </div>
      <div class="show_item go_back">
        <el-button type="info" plain @click="$router.go(-1)">返回</el-button>
      </div>
      <div class="show_item">
        活动状态：<span>{{
          $dict.activeStatus | dictFormat(activeInfo.status)
        }}</span>
      </div>
      <div class="show_item">
        活动开始时间：<span>{{ activeInfo.startTime }}</span>
      </div>
      <div class="show_item">
        活动结束时间：<span>{{ activeInfo.endTime }}</span>
      </div>

      <div class="show_item w">
        活动描述：<span v-html="activeInfo.details"></span>
      </div>
      <div class="show_item w">
        活动时间：<span>{{
          $dict.activeType | dictFormat(activeInfo.type)
        }}</span>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  props: ["activeInfo"],
  name: "activeInfo",
  data() {
    return {};
  },
};
</script>
<style scoped lang='less'>
.box-card {
  margin-bottom: 20px;
}
.show_list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .show_item {
    width: 33%;
    height: 30px;
    margin-top: 10px;
    display: flex;
    span {
      color: #5a5a5a;
      flex: 1;
    }
  }
  .go_back {
    justify-content: center !important;
  }
}
.w {
  width: 100% !important;
  padding: 10px 0;
}
</style>
