<template>
  <div class="joinActive">
    <ShowVue :activeInfo="activeInfo" />
    <el-card class="box-card" v-if="dataList.length">
      <div v-for="(item, index) in dataList" :key="item.index">
        <div class="title">
          <h3>申请记录{{ index + 1 }}</h3>
          <h3>申请时间: {{ item.applyTime }}</h3>
          <h3>
            申请状态:
            {{ $dict.applicationStatus | dictFormat(item.status) }}
          </h3>
          <h3 v-show="item.status == 2">驳回原因:{{ item.statusCause }}</h3>
        </div>
        <!-- 全时段表格 -->
        <tableListVue
          v-if="!activeInfo.type"
          :dataList="item.activityProductInfoList"
          :dataListLoading="dataListLoading"
          :type="'check'"
        >
        </tableListVue>
        <!-- 分时段baio -->
        <div v-if="activeInfo.type">
          <el-tabs
            v-model="item.timeFrameId"
            type="card"
            @tab-click="handleClick"
          >
            <el-tab-pane
              :name="records.id + ''"
              v-for="records in item.activityTimeFrameList"
              :key="records.id"
              :label="`${records.timeFrameStart} - ${records.timeFrameEnd}`"
            >
              <tableListVue
                :dataList="records.activityProductInfoList"
                :dataListLoading="dataListLoading"
                :type="'check'"
              >
              </tableListVue>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-card>
    <el-empty v-else description="暂无申请记录"></el-empty>
  </div>
</template>

<script>
import ShowVue from './components/Show.vue';
import tableListVue from './components/tableList.vue';
export default {
  name: 'applicationRecords',
  components: {
    ShowVue,
    tableListVue,
  },
  created() {
    // this.activityTimeFrameList = this.$route.query.row.activityTimeFrameList;
    // 如果分时段 默认选中第一个时间段
    this.getApplicationRecords();
  },
  data() {
    return {
      params: {},
      dataList: [], //添加商品数据列表
      activeInfo: Object.assign({}, this.$route.query.row), //活动信息
      flashTime: '', //时间筛选
      timeFrameIdList: [], //时间筛选ID数组
      multipleSelection: [], //多选数组
      activityTimeFrameList: [], //时间段数组
      dataListLoading: false,
    };
  },
  methods: {
    // 切换时间筛选回调
    handleClick(tab, event) {
      // console.log(tab);
    },
    //申请记录数据请求
    async getApplicationRecords() {
      const res = await this.$api.active.reqApplicationRecords({
        marketingId: this.activeInfo.id,
      });
      if (res.code == 200) {
        this.dataList = res.data.activityShopInfoList || [];
        this.activeInfo.type &&
          this.dataList.forEach((item) => {
            item.timeFrameId = item.activityTimeFrameList[0].id + '';
          });
        this.$message.success(res.message);
      } else {
        this.dataList = [];
        this.$message.error(res.message);
      }
    },
    // 多选商品
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style scoped lang="less">
.show_list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .show_item {
    width: 33%;
    height: 30px;
    margin-top: 10px;
    display: flex;
    span {
      color: #5a5a5a;
      flex: 1;
    }
  }
}
.filter {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  padding: 15px 0;
}

.w {
  width: 100% !important;
  padding: 10px 0;
}
.auditing {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}
.title {
  width: 1400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    padding: 15px 0;
  }
}
</style>
